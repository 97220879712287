import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Cta from '../components/cta'
import ColumnAlternatingYouTube from '../components/columnAlternatingYouTube'
import CardIcons from '../components/cardIcons.js'
import Card from '../components/card'
import Showdown from 'showdown'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      {post.frontmatter.section.length > 0 && (
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <ColumnAlternatingYouTube
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      openingpara={sections.openingpara}
                      description={sections.description}
                      vidUrl={sections.videourl}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                      link={sections.link}
                      linktext={sections.linktext}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      {post.frontmatter.feature && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-5">
                {post.frontmatter.feature.title}
              </h3>
              {post.frontmatter.feature.featurecard && (
                <MDBRow>
                  <>
                    {post.frontmatter.feature.featurecard.map(cards => {
                      return (
                        <CardIcons
                          collg="4"
                          colmd="6"
                          title={cards.title}
                          subtitle={cards.subtitle}
                          imageUrl={cards.image.childImageSharp.fixed}
                          imageAltText={cards.alttext}
                          anchorlink={cards.anchorlink}
                          anchortext={cards.anchortext}
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

    {post.frontmatter.insight && (
      <>
      {post.frontmatter.insight.map((insights, index) => (
        <section className="bg-light-blue-prods">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                
                  <span id={insights.anchorlink} className="anchor">
                     &nbsp;
                  </span> 
                    <div
                      className="mt-3 richtext richtext-image divlink"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: converter.makeHtml(insights.description), }}
                    />                  
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>
        ))}
        </>
    )}

      {post.frontmatter.compliance != '' &&
        post.frontmatter.compliance != null && (
          <>
            {post.frontmatter.compliance.map((compliances, index) => (
              <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
                <section className="bg-white" key={index}>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="12" className="pr-5 mb-5">
                        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                          {compliances.title}
                        </h2>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      {compliances.compfeature.map((compfeatures, index) => (
                        <MDBCol
                          lg="4"
                          md="6"
                          className="pb-5 item-prods d-flex"
                          key={index}
                        >
                          <div className="card border-0 flex-fill">
                            <div className="card-body">
                              <div
                                style={{
                                  minWidth: '66px',
                                  maxWidth: '66px',
                                }}
                              >
                                <Img
                                  fixed={
                                    compfeatures.image.childImageSharp.fixed
                                  }
                                  className="mb-3"
                                  alt={compfeatures.alttext}
                                />
                              </div>
                              <p className="text-medium text-blue-dark font-w-700">
                                {compfeatures.title}
                              </p>
                              <p className="text-medium text-gray-dark">
                                {compfeatures.description}
                              </p>
                            </div>
                            <div className="card-footer border-top-0">
                              <span className="text-medium-small pl-3">
                                <Link to={compfeatures.link} className="effect">
                                  {compfeatures.linktext}
                                </Link>
                              </span>
                            </div>
                          </div>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBContainer>
                </section>
              </MDBAnimation>
            ))}
          </>
        )}

      {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      helpfulres => {
                        return (
                          <>
                          {/* {console.log(post.frontmatter.helpfulresources.helpfulres.length)} */}
                          <Card
                            collg={(post.frontmatter.helpfulresources.helpfulres.length % 3 === 0) ? "4" : (post.frontmatter.helpfulresources.helpfulres.length % 4 === 0) ? "3" : "4" }
                            colmd="6"
                            height="8.5rem"
                            title={helpfulres.title}
                            subtitle={helpfulres.subtitle}
                            description={helpfulres.description}
                            imageUrl={helpfulres.image.childImageSharp.fluid}
                            imageAltText={helpfulres.alttext}
                            placement={helpfulres.placement}
                            link={
                              helpfulres.link
                                ? helpfulres.link
                                : helpfulres.document.publicURL
                            }
                            titleclass="title-small"
                            descriptionClass="text-card-small"
                          />
                          </>
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>
      )}

      <section className="bg-blue-green-gradient">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <Cta
              ctatitle={post.frontmatter.cta.title}
              ctadescription={post.frontmatter.cta.description}
              ctalink={post.frontmatter.cta.link}
              ctalinktext={post.frontmatter.cta.linktext}
            />
          </MDBContainer>
        </MDBAnimation>
      </section>
    </Layout>
  )
}
export const query = graphql`
         query($slug: String!) {
          site {
      siteMetadata {
        title
        siteUrl
      }
    }             
           markdownRemark(
             fields: { slug: { eq: $slug } }
             frontmatter: { template: { eq: "services" } }
           ) {
             frontmatter {
               meta {
                 title
                 description
               }
               title
               subtitle
               image {
                 childImageSharp {
                   fluid(maxWidth: 1920, quality: 90) {
                     ...GatsbyImageSharpFluid_withWebp
                   }
                 }
               }
               alttext
               ctatext
               ctaslug
               cta {
                 title
                 description
                 linktext
                 link
               }
               section {
                 title
                 subtitle
                 openingpara
                 description
                 videourl
                 placement
                 link
                 linktext
               }
               feature {
                 title
                 btntext
                 btnlink
                 document {
                   publicURL
                 }
                 featurecard {
                   title
                   subtitle
                   alttext
                   anchorlink
                   anchortext
                   image {
                     childImageSharp {
                       fixed(width: 70, height: 70, quality: 90) {
                         ...GatsbyImageSharpFixed_withWebp
                       }
                     }
                   }
                 }
               }
               insight {
                   anchorlink
                   description
                }
               compliance {
                 title
                 compfeature {
                   title
                   description
                   link
                   linktext
                   alttext
                   image {
                     childImageSharp {
                       fixed(width: 46, height: 46, quality: 90) {
                         ...GatsbyImageSharpFixed_withWebp
                       }
                     }
                   }
                 }
               }
               helpfulresources {
                 title
                 helpfulres {
                   title
                   subtitle
                   description
                   placement
                   link
                   alttext
                   image {
                     childImageSharp {
                       fluid(maxWidth: 960, quality: 90) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                   document {
                     publicURL
                   }
                 }
               }
             }
             html
           }
         }
       `
