import React from 'react'
import { MDBCol } from 'mdbreact'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

class CardIcons extends React.Component {
  render() {
    return (
      <>
        <MDBCol
          lg={this.props.collg}
          md={this.props.colmd}
          className="pb-30 d-flex"
        >
          <div className="card flex-fill outline card-hover">
            <div className="card-body">
              <div
                style={{
                  minWidth: '85px',
                  maxWidth: '85px',
                }}
              >
                <Img
                  fixed={this.props.imageUrl}
                  className="mr-1"
                  alt={this.props.imageAltText}
                />
              </div>

              <div>
                <h4 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3">
                  {this.props.title}
                </h4>
                {this.props.subtitle != null && (
                  <p className="font-w-400 text-medium pt-2">
                    {this.props.subtitle}
                  </p>
                )}
                {this.props.anchorlink && (
                  <a href={this.props.anchorlink} className="text-small effect" >
                    {this.props.anchortext}
                  </a>
                )}
              </div>
            </div>
          </div>
        </MDBCol>
      </>
    )
  }
}

export default CardIcons
