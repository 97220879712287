import React, { Component, Fragment } from "react"
import Cookie from 'universal-cookie'

class CookiesParseHtml extends Component {
    constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: null,
      html: this.props.html, 
      str: "", 
      load: false,
      expired: "false"
    }
  }

  handleCookies = (consent, html, start) => {
    var s = html.indexOf("<iframe", start)
    var e = html.indexOf("</", s+1)
    var str = html.substring(s, e+9)

    // consent.categories.includes("targeting") 
    // ? str = str.replace(/ data-src="/g, ' src="') 
    // : str = str.replace(/ src="/g, ' data-src="' ) + '<div class="embed-notice">Accept "targeting" cookies to display this video.</div>'
    this.setState({ html: html.substring(0, s) + str }) 
}

  componentDidMount() {
    const cookie = new Cookie()
    const consent = cookie.get("CookieScriptConsent")
    
    if (process.env.NODE_ENV !== "development") { 
        this.handleCookies(consent, this.props.html, 0)
    }
  }

  render() {
    return (
      <Fragment key="1"> 
        <div dangerouslySetInnerHTML={{ __html: this.state.html }} />      
      </Fragment>
    )
  }
}
export default CookiesParseHtml