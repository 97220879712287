import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import CookiesParseHtml from "./cookiesParseHtml"

class ColumnAlternatingYouTube extends React.Component {
  render() {
    return (
      <MDBRow className={`about-item align-items-top ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        {(this.props.vidUrl != null && this.props.vidUrl != "") && (
          <MDBCol lg="7">
            <div className="embed-responsive embed-responsive-16by9">
              {/* <iframe className="embed-responsive-item rounded border" src={this.props.vidUrl} ></iframe> */}
              <CookiesParseHtml html={`<div><iframe className="embed-responsive-item rounded border" src="${this.props.vidUrl}?rel=0&modestbranding=1"></iframe></div>`} />
            </div>
          </MDBCol>
        )}

        <MDBCol lg={(this.props.vidUrl != null && this.props.vidUrl.length >= 1) ? '5' : '12'} className="mt-4 mt-lg-0 mb-4 mb-lg-0" >
          <h3 className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
            {this.props.title}
          </h3>

          {this.props.subtitle != null && (
            <h4 className="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {this.props.subtitle}
            </h4>
          )}

          <div
            className="mt-3 mb-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: this.props.openingpara }}
          />
        </MDBCol>

        {this.props.description != '' && this.props.description != null && (
          <MDBCol lg="12">
            <div
              className="mt-md-3 mb-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />
          </MDBCol>
        )}

        <MDBCol lg="12">
          {this.props.link ? (
            this.props.link.charAt(0) == '/' ? (
              <Link to={this.props.link} className="nav-link btn btn-mdb-color mt-4">
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.linktext}
              </Link>
            ) : (
              <a href={this.props.link} className="nav-link btn btn-mdb-color btn-sm-block mt-4" target="_blank" rel="noopener">
                {this.props.icon == 'right' ? (
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                ) : (
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                )}
                {this.props.linktext}
              </a>
            )
          ) : null}

          {(this.props.ctalink && this.props.ctatext !== " ") ? (
            this.props.ctalink.charAt(0) == '/' ? (
              <Link to={this.props.ctalink} className="nav-link btn btn-mdb-color mt-4">
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctatext}
              </Link>
            ) : (
              <a href={this.props.ctalink} className="nav-link btn btn-mdb-color btn-sm-block mt-4" target="_blank" rel="noopener">
                {this.props.icon == 'right' ? (
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                ) : (
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                )}
                {this.props.ctatext}
              </a>
            )
          ) : null}
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ColumnAlternatingYouTube